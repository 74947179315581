* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

img {
  max-inline-size: 100%;
  block-size: auto;
}

html {
  font-size: clamp(1rem, 0.75rem + 1.5vw, 2rem);
}

div.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

img.logo {
  max-width: 300px;
  min-width: 98px;
  width: 16%;
}

.soon {
  font-family: Roboto, sans-serif;
  font-size: 600%;
  color: #121212;
  line-height: 0.9;
  font-weight: 600;
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media (max-width: 364px) {
  p.soon {
    font-size: 550%;
  }
}

@media (max-width: 320px) {
  p.soon {
    font-size: 500%;
  }
}

article {
  max-inline-size: 66ch;
}
